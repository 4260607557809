import React from 'react';
import Seo from "../seo";
import {Button, Col, Grid, Row, Typography} from "antd";
import {Link} from "gatsby";
import "../../styles/main.css"
import {StaticImage} from "gatsby-plugin-image";
import "../../images/mobileBanner.png"

const {useBreakpoint} = Grid
const {Text} = Typography
export default function Landing() {
  const screens = useBreakpoint()
  return (
    <div className={"landing"}>
      <Seo title="Landing"/>
      {screens.xs &&
        <Row justify="center" style={{height: "100vh"}}>
          <Col xs={22} sm={0} style={{textAlign: "center", color: "white"}}>
            <br/>
            <br/>
            <br/>
            <p>
              CENTRE HOSPITALO UNIVERSITAIRE DE BAB EL OUED <br/>
              Hôpital Mohamed Lamine Debaghine <br/>
              Direction des Activités Médicale et Paramédicale
            </p>
            <br/>
            <p>
              EN COLLABORATION AVEC L'ASSOCIATION NATIONALE DES DIABETOLOGUES ET
              ENDOCRINOLOGUES LIBERAUX (L'ANDEL)
            </p>
            <StaticImage
              src="../../images/mobileBanner.png"
              alt="bannerMobile"
            />
          </Col>
          <Col sm={6} xs={17}
               style={{textAlign: "center", color: "rgb(0,6,98)"}}>
            <Button type="primary" size="large" style={{marginBottom: "25%"}}
                    block>
              <Link to="/inscription">
                Inscription
              </Link>
            </Button>
          </Col>
        </Row>
      }
      {!screens.xs &&
        <Row
          justify="center"
          align="bottom"
          style={{height: "100vh"}}
        >
          <Col sm={6} xs={17}
               style={{textAlign: "center", color: "rgb(0,6,98)"}}>
            <Button type="primary" size="large" style={{marginBottom: "25%"}}
                    block>
              <Link to="/inscription">
                Inscription
              </Link>
            </Button>
          
          </Col>
        </Row>
      }
      <div style={{position: "fixed", bottom: 25, textAlign: "center",width:"100%"}}>
        <Text>
          Propulsé par: Lotus Medias
        </Text>
      </div>
    </div>
  );
}